<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <router-link
            v-if="$can('generate_promotion_code')"
            :to="{ name: 'Add Promotion Code' }"
            class="btn btn-primary"
            ><i class="fa fa-plus"></i> Generate</router-link
          >&nbsp;
          <button
            v-if="$can('export_promotion_code')"
            class="btn btn-success"
            type="button"
            @click="exportTable"
          >
            Export
          </button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="12" md="3" lg="3">
        <b-form-group
          class="mb-3"
          label="Search :"
          label-for="search"
          description="Searchable : Promo Name, Promo Code"
        >
          <b-input-group>
            <b-form-input
              id="search"
              type="search"
              v-model="filterText"
              placeholder="Promo Name, Promo Code ..."
              @keyup.enter="doFilter"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button variant="secondary" @click="resetFilter" type="button">
                Reset
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3" lg="3">
        <b-form-group class="mb-3" label="Used At :" label-for="daterange">
          <b-input-group>
            <b-input-group-prepend is-text>
              <i class="fa fa-calendar"></i>
            </b-input-group-prepend>
            <range-picker
              id="daterange"
              :start="startDate"
              :end="endDate"
              @picker="doDateFilter"
            ></range-picker>
            <b-input-group-append>
              <b-button
                type="button"
                variant="secondary"
                @click="resetDateFilter"
                >Reset</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3" lg="3">
        <b-form-group id="module" label="Filter by Module" label-for="module">
          <b-form-select
            id="module"
            v-model="module"
            @change="doFilterModule"
            :options="[
              { value: '', text: 'All' },
              { value: 1, text: 'Beli Emas' },
              { value: 23, text: 'Hadiah Emas' },
              { value: 32, text: 'Panen Emas' }
            ]"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="3" lg="3">
        <b-form-group id="code_type" label="Code Type" label-for="code_type">
          <b-form-select
            id="code_type"
            v-model="code_type"
            @change="doFilterCodeType"
            :options="[
              { value: '', text: 'All' },
              { value: 0, text: 'General' },
              { value: 1, text: 'Unique' }
            ]"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="code-slot" slot-scope="prop">
        <b-badge pill variant="dark" class="badge--code p-3">{{
          prop.rowData.code
        }}</b-badge>
      </template>
      <template slot="period-slot" slot-scope="prop">
        <span
          :style="[
            timeNow >= prop.rowData.end_to
              ? { color: 'red' }
              : { color: 'green' }
          ]"
          >{{ prop.rowData.start_from }}</span
        >
        <br />
        <span
          :style="[
            timeNow >= prop.rowData.end_to
              ? { color: 'red' }
              : { color: 'green' }
          ]"
        >
          -
        </span>
        <br />
        <span
          :style="[
            timeNow >= prop.rowData.end_to
              ? { color: 'red' }
              : { color: 'green' }
          ]"
          >{{ prop.rowData.end_to }}</span
        >
      </template>
      <template slot="code_type-slot" slot-scope="prop">
        <span v-if="prop.rowData.code_type === 0">General</span>
        <span v-else>Unique</span>
      </template>
      <template slot="is_available-slot" slot-scope="prop">
        <span v-if="prop.rowData.is_available" class="ui green label"
          ><i class="fa fa-check"></i
        ></span>
        <span v-else class="ui red label"><i class="fa fa-times"></i></span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div
          class="custom-actions"
          style="display: flex; flex-direction: column"
        >
          <button
            class="btn btn-info mb-2"
            v-if="$can('detail_promotion_code')"
            @click="onAction('detail-item', prop.rowData, prop.rowIndex)"
          >
            Detail
          </button>
          <button
            :class="
              prop.rowData.status === true
                ? 'btn btn-warning mb-2'
                : 'btn btn-success mb-2'
            "
            v-if="$can('detail_promotion_code') && prop.rowData.code_type === 0"
            @click="onAction('activate-item', prop.rowData, prop.rowIndex)"
          >
            {{ prop.rowData.status === true ? "Hide" : "Show" }}
          </button>
          <div
            style="display: flex; justify-content: space-between; align-items: center"
          >
            <button
              v-if="$can('delete_promotion_code')"
              class="btn btn-danger"
              @click="onAction('delete-item', prop.rowData)"
            >
              <i class="fa fa-trash-o"></i>
            </button>
            <button
              class="btn btn-primary"
              v-if="$can('edit_promotion_code')"
              @click="onAction('edit-item', prop.rowData, prop.rowIndex)"
            >
              <i class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import accounting from "accounting";
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import moment from "moment/src/moment";
import axios from "axios";
let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statusOptions: [
        { text: "Pilih Status Transaksi", value: "" },
        { text: "Dibatalkan", value: "cancelled" },
        { text: "Success", value: "success" },
        { text: "Expired", value: "expired" },
        { text: "Menunggu Pembayaran", value: "pending" }
      ],
      status: "",
      startDate: "",
      endDate: "",
      isLoading: false,
      filterText: "",
      timeNow: moment().format("YYYY-MM-DD hh:mm:ss"),
      organization: "",
      module: "",
      code_type: "",
      organizationOptions: [],
      errors: {
        code: "",
        message: "",
        status: ""
      },
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `promotion-code`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sortOrder: [
        {
          field: "created_at",
          sortField: "promotion_codes.created_at",
          direction: "desc"
        }
      ],
      moreParams: {},
      fields: [
        {
          name: "code-slot",
          title: "Promo Code",
          width: "13%"
        },
        {
          name: "code_type-slot",
          title: "Code Type"
        },
        {
          name: "name",
          title: "Promo Name"
        },
        {
          name: "period-slot",
          title: "Promo Period"
        },
        {
          name: "organization",
          title: "Organization/Partner"
        },
        {
          name: "user_type",
          title: "User Type"
        },
        {
          name: "quota",
          title: "Kuota"
        },
        {
          name: "is_available-slot", // * Available Status
          title: "Status"
        },
        {
          name: "min_amount",
          title: "Min Transaksi"
        },
        {
          name: "promotion_type",
          title: "Tipe Promosi"
        },
        {
          name: "amount",
          title: "Nilai Promosi"
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ]
    };
  },
  created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
    axios
      .get(
        process.env.VUE_APP_SECRET +
          "admin/v2/command/organization-command?reseller=true"
      )
      .then(response => {
        let data = response.data.data;

        let attr = [{ text: "Pilih Organisasi", value: "" }];
        data.map(v => {
          return attr.push({ text: v.text, value: v.value });
        });
        this.organizationOptions = attr;
      });
  },
  methods: {
    exportTable() {
      this.isLoading = true;

      // if (this.startDate != "" && this.endDate != "") {
      //   startDate = this.startDate;
      //   endDate = this.endDate;
      // } else {
      //   endDate = this.$setDate.format("YYYY-MM-DD");
      //   startDate = this.$setDate.subtract(6, "days").format("YYYY-MM-DD");
      // }

      const filters = Object.entries(this.moreParams)
        .filter(([key]) => {
          return key !== "module" && key !== "code_type";
        })
        .map(([key, value]) => {
          return `${key}=${value}`;
        });
      const newParams = filters.length > 0 ? `?${filters.join("&")}` : "";

      this.$http
        .get(`data-promotion-code${newParams}`)
        .then(result => {
          this.isLoading = false;
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        });
    },
    doFilter() {
      this.$events.fire("filter-set", this.filterText);
    },
    doFilterModule() {
      this.$events.$emit("module-set", this.module);
    },
    onModuleFilterSet() {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "module")
      );

      this.moreParams = this.module
        ? { ...newParams, module: this.module }
        : newParams;

      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    doFilterCodeType() {
      this.$events.$emit("code-type-set", this.code_type);
    },
    onCodeTypeFilterSet() {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "code_type")
      );

      this.moreParams =
        this.code_type !== ""
          ? { ...newParams, code_type: this.code_type }
          : newParams;

      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire("date-set", [this.startDate, this.endDate]);
    },
    onDateSet() {
      this.start_from = this.startDate;
      this.end_to = this.endDate;

      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => {
          return key !== "start_from" && key !== "end_to";
        })
      );

      this.moreParams =
        this.startDate && this.startDate
          ? {
              ...newParams,
              start_from: this.startDate,
              end_to: this.endDate
            }
          : newParams;

      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    resetFilter() {
      this.filterText = ""; // clear the text in text input
      this.$events.fire("filter-reset");
    },
    weight(value) {
      return value + " Gram";
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
      this.$events.$emit("date-filter-reset");
    },
    formatNumber(value) {
      return "Rp." + accounting.formatNumber(value, 2);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onAction(action, data) {
      if (action == "detail-item") {
        this.$router.push({
          name: "Detail Promo Code",
          params: { id: data.id }
        });
      } else if (action === "edit-item") {
        this.$router.push({
          name: "Edit Promo Code",
          params: { id: data.id }
        });
      } else if (action === "activate-item") {
        this.$swal
          .fire({
            title: "Confirmation",
            html: `<p>Apakah kamu yakin ${
              data.status === true
                ? "<strong>Hide</strong>"
                : "<strong>Show</strong>"
            } kode Promo <strong>${data.code} - ${data.name}?</strong></p>`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Batal"
          })
          .then(result => {
            if (result.value) {
              this.$http
                .post(`activate-promotion-code/` + data.id)
                .then(() => {
                  this.$swal
                    .fire({
                      title: "Success!",
                      html: `<p>Berhasil ${
                        data.status === true
                          ? "<strong>Hide</strong>"
                          : "<strong>Show</strong>"
                      } kode Promo <strong>${data.code} - ${
                        data.name
                      }</strong></p>`,
                      type: "success"
                    })
                    .then(() => {
                      location.reload();
                    });
                })
                .catch(error => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                });
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelled",
                "Promotion Code was successfully canceled for Show/Hide!",
                "error"
              );
            }
          });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You will not be able to recover this Promotion Code!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it"
          })
          .then(result => {
            if (result.value) {
              this.$http
                .delete(`promotion-code/` + data.id)
                .then(() => {
                  this.$swal
                    .fire(
                      "Deleted!",
                      "Promotion Code has been deleted.",
                      "success"
                    )
                    .then(() => {
                      location.reload();
                    });
                })
                .catch(error => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                });
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelled",
                "Promotion Code was successfully canceled for deletion!",
                "error"
              );
            }
          });
      }
    },
    onFilterSet() {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "filter")
      );

      this.moreParams = this.filterText
        ? { ...newParams, filter: this.filterText }
        : newParams;

      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "filter")
      );
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onDateFilterReset() {
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => {
          return key !== "start_from" && key !== "end_to";
        })
      );
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    }
  },
  mounted() {
    this.$events.$on("filter-set", eventData => this.onFilterSet(eventData));
    this.$events.$on("date-set", eventData => this.onDateSet(eventData));
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
    this.$events.$on("module-set", eventData =>
      this.onModuleFilterSet(eventData)
    );
    this.$events.$on("code-type-set", eventData =>
      this.onCodeTypeFilterSet(eventData)
    );
  }
};
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
